<template>
  <div :class="['fullHeight', login_first_time == 1 ? 'p-3' : 'bg__primary']">
    <div class="loginPage w-100" v-if="login_first_time != 1">
      <div class="container">
        <div class="row align-items-center wrap-space">
          <div class="col-lg-4 col-md-5">
            <div
              class="
                left__panel
                d-flex
                justify-content-center
                align-items-start
                pt-4
              "
            >
              <router-link to="">
                <img
                  :src="require(`@/assets/images/compilerx_logo.png`)"
                  class="area width: 300px; height: auto;"
                  alt="Logo"
                />
              </router-link>
            </div>
          </div>
          <div class="offset-lg-2 col-lg-5 offset-md-1 col-md-6 mt-3 mt-md-0">
            <div class="right__panel">
              <div class="signIn__head">
                <h2 class="header__text mb-2 pb-1">Sign in</h2>
                <h5 class="subHeader__text">
                  Get Information at your fingertips!
                </h5>
              </div>
              <div class="mb-2">
                <input
                  type="email"
                  class="form-control py-3"
                  placeholder="Email address"
                  v-model="email"
                  @keypress="handleEnterKeyEvent"
                />
              </div>
              <div class="mb-3">
                <div class="form-group field__password">
                  <input
                    :type="[inputTypePass ? 'password' : 'text']"
                    autocomplete="new-password"
                    class="form-control inputSmall--password"
                    :placeholder="'Password'"
                    v-model.trim="password"
                    @keypress="handleEnterKeyEvent"
                  />
                  <button
                    class="field__password--switch"
                    type="button"
                    title="Show password"
                    data-bs-toggle="tooltip"
                    :data-password="[inputTypePass ? 'show' : 'hidden']"
                    @click="passToggle"
                  >
                    <img
                      :src="require(`@/assets/images/pass_show_white.png`)"
                      class="pass__show w-100"
                      alt="Pass Show"
                    />
                    <img
                      :src="require(`@/assets/images/pass_hide_white.png`)"
                      class="pass__hide w-100"
                      alt="Pass Hide"
                    />
                  </button>
                </div>
              </div>
              <button
                type="button"
                class="button-area button--block mb-3 w-100 p-0"
                @click="sign_in()"
                v-if="!loading"
              >
                Sign in Now
              </button>
              <button
                class="button-area button--block mb-3 w-100 p-0"
                v-if="loading"
              >
                <i class="fas fa-circle-notch fa-spin"></i>
              </button>
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  flex-wrap
                "
              >
                <div class="d-flex text-center">
                  <input
                    class=""
                    type="checkbox"
                    value="lsRememberMe"
                    id="rememberMe"
                    style="margin-right: 4px"
                    v-model="remember_me"
                  />
                  <label for="rememberMe" class="remember-it link__small"
                    >Remember me</label
                  >
                </div>
                <router-link to="/sign-up" class="link__small me-2 mt-1 mb-1"
                  >Don't have an account? Sign up</router-link
                >
              </div>
              <!-- <div class="d-flex justify-content-start flex-wrap mt-4">
                <router-link to="/forgot-password" class="link__small mb-2"
                  >Forget Password?</router-link
                >
              </div> -->
              <div class="d-flex justify-content-start flex-wrap mt-4">
                <a href="#" class="link__small text-blue mb-2" @click="forgetPasswordRedirect"
                  >Forget Password?</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Confetti v-if="login_first_time == 1" /> -->
    <div class="congrats" v-if="login_first_time == 1">
      <h2 class="header__text">Congratulations!</h2>

      <div class="congrats__text">
        <p class="para">You have earned</p>
        <h1 class="txt__primary congrats__head">{{ credit }} Credits</h1>
        <!-- <p class="para">1 credit = ${{per_credit_amount}}</p> -->
        <p class="para">
          Your Trial period will be ended on
          {{ trial_period_end_date }}
        </p>
        <div class="contactActionButtons mb-3 mt-4 justify-content-center">
          <router-link to="/dashboard" class="ctBtn ctBtn__icon ctBtn__upload">
            <path
              d="M4.235,11.516A4.276,4.276,0,0,1,0,7.2,4.291,4.291,0,0,1,3.776,2.9a5.263,5.263,0,0,1,9.887,1.44,3.593,3.593,0,0,1-.251,7.172Z"
              stroke="none"
            />
            <path
              d="M 13.41270065307617 11.51640033721924 C 15.3612003326416 11.51640033721924 16.94250106811523 9.904500007629395 16.94250106811523 7.917300224304199 C 16.94250106811523 6.01740026473999 15.49170017242432 4.477499961853027 13.66380023956299 4.344300270080566 C 13.18320083618164 1.867500185966492 11.04120063781738 1.686096169351003e-07 8.471700668334961 1.686096169351003e-07 C 6.431400299072266 1.686096169351003e-07 4.662900447845459 1.180800199508667 3.776400566101074 2.904300212860107 C 1.655100584030151 3.138300180435181 5.340576194612368e-07 4.969799995422363 5.340576194612368e-07 7.198200225830078 C 5.340576194612368e-07 9.584099769592285 1.895400524139404 11.51640033721924 4.235400676727295 11.51640033721924 L 13.41270065307617 11.51640033721924 M 13.41270065307617 13.01640033721924 L 4.235400676727295 13.01640033721924 C 1.072890520095825 13.01640033721924 -1.499999523162842 10.40637016296387 -1.499999523162842 7.198200225830078 C -1.499999523162842 4.494261264801025 0.313761293888092 2.194272518157959 2.830748319625854 1.55478036403656 C 4.088004112243652 -0.346674382686615 6.187225341796875 -1.49999988079071 8.471700668334961 -1.49999988079071 C 11.35958576202393 -1.49999988079071 13.90217304229736 0.3611763715744019 14.86060047149658 3.049917936325073 C 15.70658397674561 3.306333780288696 16.4751033782959 3.785242080688477 17.08975028991699 4.44972038269043 C 17.96209144592285 5.392760276794434 18.44250106811523 6.624240398406982 18.44250106811523 7.917300224304199 C 18.44250106811523 10.72895050048828 16.1861400604248 13.01640033721924 13.41270065307617 13.01640033721924 Z"
              stroke="none"
              fill="#fff"
            />
            <span>Click here</span>
          </router-link>
        </div>
      </div>
      <!-- <p class="para">
        Also you can
        <router-link class="butCreditLink" to="/credits"
          >Request Credits</router-link
        >
      </p> -->

      <router-link class="close__congrats" to="/dashboard">X</router-link>
    </div>
    <!-- <Preloader :show="loader" /> -->
  </div>
</template>
<script>
// import Preloader from "../../components/Preloader";
// import spinner from "../../components/Spinner";
// import Confetti from "../../components/Confetti";
// import ContactListVue from '../contacts/ContactList.vue';
import { axiosHeaderL, axiosHeaderP } from "@/services/axiosinstance";
var CryptoJS = require("crypto-js");

export default {
  //   components: { spinner, Confetti },
  data() {
    return {
      // logo : require('../../../../public/images/logo-big.png'),
      inputTypePass: true,
      login_first_time: 0,
      email: "",
      password: "",
      loader: true,
      logging_in: false,
      loading: false,

      browser_name: "",
      full_version: "",
      major_version: "",
      app_name: "",
      user_agent: "",
      os_name: "",

      credit: 100,
      per_credit_amount: 1,
      // trial_period_end_date: this.$moment().format("LLLL"),
      remember_me: false,
    };
  },
  methods: {
    passToggle() {
      // console.log("hi")
      // console.log(this.inputTypePass)
      this.inputTypePass = !this.inputTypePass;
    },

    forgetPasswordRedirect(){
      var current_URL = window.location.href;
      var redirect_URL = 'https://compilerx.com'
      if(current_URL.includes('dev')){
        redirect_URL = 'https://dev.compilerx.com'
      }else if(current_URL.includes('localhost')){
       redirect_URL ='http://localhost:8000'
      }
      window.open(redirect_URL+"/forgot-password", '_blank');
    },

    // hideloder() {
    //   const vm = this;
    //   setTimeout(() => {
    //     vm.loader = false;
    //   }, 3000);
    // },
    getBrowserInfo() {
      //=================== Get User browser Information And IP ============//

      //   var nVer = navigator.appVersion;
      var nAgt = navigator.userAgent;
      var browserName = navigator.appName;
      var fullVersion = "" + parseFloat(navigator.appVersion);
      var majorVersion = parseInt(navigator.appVersion, 10);
      var nameOffset, verOffset, ix;

      // In Opera, the true version is after "Opera" or after "Version"
      if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
          fullVersion = nAgt.substring(verOffset + 8);
      }
      // In MSIE, the true version is after "MSIE" in userAgent
      else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
      }
      // In Chrome, the true version is after "Chrome"
      else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
      }
      // In Safari, the true version is after "Safari" or after "Version"
      else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
          fullVersion = nAgt.substring(verOffset + 8);
      }
      // In Firefox, the true version is after "Firefox"
      else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
      }
      // In most other browsers, "name/version" is at the end of userAgent
      else if (
        (nameOffset = nAgt.lastIndexOf(" ") + 1) <
        (verOffset = nAgt.lastIndexOf("/"))
      ) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
          browserName = navigator.appName;
        }
      }
      // trim the fullVersion string at semicolon/space if present
      if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
      if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

      majorVersion = parseInt("" + fullVersion, 10);
      if (isNaN(majorVersion)) {
        fullVersion = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
      }

      this.os_name = "Unknown OS";
      if (navigator.appVersion.indexOf("Win") != -1) this.os_name = "Windows";
      if (navigator.appVersion.indexOf("Mac") != -1) this.os_name = "MacOS";
      if (navigator.appVersion.indexOf("X11") != -1) this.os_name = "UNIX";
      if (navigator.appVersion.indexOf("Linux") != -1) this.os_name = "Linux";

      this.browser_name = browserName;
      this.full_version = fullVersion;
      this.major_version = majorVersion;
      this.app_name = navigator.appName;
      this.user_agent = navigator.userAgent;
    },
    //======= For Server ====//
    sign_in() {
      if (this.email.trim().length == 0) {
        this.$toasted.show("Please provide an email!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }

      var ciphertext = CryptoJS.AES.encrypt(
        this.password,
        "klizos.com"
      ).toString();

      // console.log(ciphertext)

      this.loading = true;
      window.axiosL
        .post("api/auth/login", {
          device_type: 1,
          email: this.email,
          password: this.password,
          notification_token: localStorage.getItem("f_token"),
          browser_name: this.browser_name,
          full_version: this.full_version,
          major_version: this.major_version,
          app_name: this.app_name,
          user_agent: this.user_agent,
          os_name: this.os_name,
          remember_me: this.remember_me,
          remember_password: ciphertext,
        })
        .then((res) => {
          if (res.data.status) {
            // console.log(res.data)
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("role", res.data.data.role);
            localStorage.setItem("uid", res.data.data.id);
            localStorage.setItem("api_token", res.data.access_token);
            this.loading = false;
            this.$toasted.show("loggin successfully!", {
              icon: "check-circle",
              className: "error-class",
            });

            axiosHeaderP.defaults.headers.common["Authorization"] =
              localStorage.getItem("token");
            axiosHeaderL.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token");

            this.$store.dispatch("setLoggedIn", true);
            // console.log(localStorage.getItem('cart'))
            if (localStorage.getItem("cart")) {
              this.storeCartItem();
            } else {
              this.$helpers.getCart();
            }

            if (this.$store.getters.getpathFrom == "") {
              this.$router.push("/");
            } else {
              this.$router.push(this.$store.getters.getpathFrom);
            }
          } else {
            this.$toasted.show(res.data.message, {
              icon: "check-circle",
              className: "error-class",
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          // this.logging_in = false;
          this.loading = false;
          this.$toasted.show(err.response.data.message, {
            icon: "times-circle",
            className: "error-class",
          });
        });
    },

    //======Store cart item into DB=========//
    storeCartItem() {
      let Cart = JSON.parse(localStorage.getItem("cart"));
      localStorage.removeItem("cart");
      let product_ids = [];
      Cart.map((val) => {
        product_ids.push(val.product_id);
      });

      let ids = product_ids.join(",");
      // test
      this.formData = new FormData();
      this.formData.append("product_ids", ids);
      axiosHeaderP
        .post("add-to-cart", this.formData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.$helpers.getCart();
    },

    async storeAccessToken() {
      window.axiosL
        .post(
          "/api/auth/store-token",
          {
            token: localStorage.getItem("token"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    handleEnterKeyEvent(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 13) {
        this.sign_in();
      } else {
        return true;
      }
    },
    rememberMeSign() {
      window.axiosL
        .post("/api/auth/remember-me")
        .then((res) => {
          if (res.data.status) {
            var password = CryptoJS.AES.decrypt(
              res.data.password,
              "klizos.com"
            );
            this.email = res.data.email;
            this.password = password.toString(CryptoJS.enc.Utf8);
            this.remember_me = true;
            this.$toasted.show("Welcome Back " + res.data.first_name, {
              icon: "check-circle",
              className: "success-class",
            });
          }
        })
        .catch(() => {
          //    do nothing
        });
    },
  },
  metaInfo() {
    return {
      title: "CompilerX || Login",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Login to CompilerX",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap-space {
  margin-top: 150px !important;
  margin-bottom: 200px;
}
.area {
  border: none;
  background-color: transparent;
}
.button-area {
  padding: 0.7rem 1rem !important;
  color: #0091ff;
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
  font-weight: 600 !important;
  /* border: 1px solid #0091ff; */
}
.remember-it {
  margin: -6px 3px;
}
h2.header__text.mb-2 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 0 !important;
}
h5.subHeader__text {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 15px;
}
a.link__small{
    margin-top: -27px;
}
.field__password {
  position: relative;
}
.field__password--switch {
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
}
.field__password--switch[data-password="show"] .pass__show {
  display: none;
}
.field__password--switch[data-password="hidden"] .pass__hide {
  display: none;
}
</style>
