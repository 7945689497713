<template>
  <div class="addCart">
    <div class="container">
      <div style="overflow-x:auto;">
        <table class="table-addCart w-100 mt-5 p-2">
        <tr class="Add-badge p-5">
          <th class="p-3">Product Name</th>
          <th>Total Contact</th>
          <th>Seller</th>
          <th>Price</th>
          <th class="page-style">Action</th>
        </tr>
        <tr class="tr-bages bg-white"  v-for="(cart, index) in carts"
              :key="index">
          <td class="p-3">
            <span>{{cart.title}}</span>
          </td>
          <td>{{cart.row_count}}</td>
          <td class="w-20">
            <span class="user-wrap"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-person text-white rounded-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                /></svg
            ></span>
            <span class="pl-2">{{cart.seller}}</span>
          </td>
          <td>{{cart.price}}</td>
          <td class="page-style">
            <span
              ><button class="btn-remove" @click="removeCart(index)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-x-lg text-center pr-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                  /></svg
                >Remove
              </button></span
            >
          </td>
        </tr>
      </table>
      </div>
      <div class="mt-2 amount-wrap-area">
        <span class="amount-wrap d-flex justify-content-end font-weight-bolder"
          >Total Amount: <b class="pl-2"> {{total_price}}</b></span
        >
      </div>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-purchase mt-3 text-white" @click="getpurchasemodalopen">
          Purchased
        </button>
      </div>
    </div>
        <!-- MODAL -->
    <Dialog />
  </div>
</template>
<script>
import { axiosHeaderP } from "@/services/axiosinstance";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "AddCart",
  components: {Dialog},
  data() {
    return {
      ids: [],
      // total_price : 0
      // carts: [],
    };
  },
  created() {
    // this.getCartItems();
  },
  computed: {
    RemainLength() {
      var l = this.title.length;
      //  var remain = this.title_limit-l
      return l;
    },
    carts(){
      return this.$store.getters.getCart
    },

    total_price(){
      return this.calculate_total_price(this.$store.getters.getCart)
    }
  },

  // watch: {
  //   carts(newVal){
  //     this.calculate_total_price(newVal)
  //   }
  // },
  methods: {
    // getCartItems() {
    //   this.carts = this.$store.getters.getCart
      
    //   if (this.carts.length==0){
    //     if (this.$store.getters.getLoggedIn){
    //       this.$helpers.getCart()
    //     }
    //   }
    //   else{
    //     this.carts = this.$store.getters.getCart
    //   }
    //   alert(this.carts)
    // },
        getpurchasemodalopen() {
      this.$modal.show("Dialog", {
        title: "Are you sure?",
        text: "Do you want to purchase this list?",
        buttons: [
          {
            title: "No",
            handler: () => {
              this.$modal.hide("Dialog");
            },
          },
          {
            title: "Yes",
            handler: () => {
              this.purchase();
            },
          },
        ],
      });
    },

    async purchase() {
      if (this.$store.getters.getLoggedIn) {
        this.formData = new FormData();
        this.formData.append("product_ids", this.ids.join());
        await axiosHeaderP
          .post("cart-purchase", this.formData)
          .then((res) => {
              this.$store.dispatch(
                "setUserCredit",
                res.data.data.current_credit
              );

              this.$modal.hide("Dialog");
              this.$toasted.show(res.data.message, {
                icon: "check",
                className: "succes-class",
              });
              this.$store.state.cart = [];
              // this.Lists[index].purchased = true;
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.show(err.response.data.message, {
              icon: "times-circle",
              className: "error-class",
            });
            this.$modal.hide("Dialog");
          });
      } else {
        this.$router.push("/login");
      }
    },
    calculate_total_price(cart){
      let prices = []
      cart.map((val) => {
        prices.push(val.price);
        this.ids.push(val.product_id);
      });

      var lodash = require('lodash');
      var sum = lodash.sum(prices);
      return sum
      // this.total_price = sum

    },

    async removeCart(index){
      let product_id = this.$store.getters.getCart[index].product_id;
      this.$store.state.cart.splice(index,1);
      // localStorage.setItem("cart", JSON.stringify(this.$store.getters.getCart));
      if(this.$store.getters.getLoggedIn){
        this.formData = new FormData();
        this.formData.append("product_id", product_id);
        await axiosHeaderP
          .post("remove-cart", this.formData)
          .then((res)=>{
            this.$toasted.show(res.data.message, {
                  icon: "check",
                  className: "succes-class",
                });
           this.carts = this.$store.getters.getCart  
          })
          .catch((err)=>{
            this.$toasted.show(err.response.data.message, {
                icon: "times-circle",
                className: "error-class",
              });
          })
      }
      else{
        localStorage.setItem("cart", JSON.stringify(this.$store.getters.getCart));
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.page-style {
  display: inline-block;
  float: right;
  padding-top: 15px;
  white-space: nowrap;
  padding-right: 40px;
}
.btn-purchase {
  background: #ea4639;
  padding: 10px 50px;
}
.tr-bages {
  border-bottom: 1px solid #96969663;
  box-shadow: 0px 0px 18px 0px #80808029;
}
.Add-badge {
  background: #49a1ce;
  color: #fff;
}
.Add-badge th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Add-badge th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.table-addCart {
  margin-top: 70px !important;
}
.user-wrap[data-v-4f39625a] {
  background: #0091ff;
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 35px;
  text-align: center;
}
.btn-remove {
  background: #ffb3bf;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 3px 10px;
  font-size: 13px;
  border-radius: 5px;
  font-weight: bolder;
  background: #ffb3bf;
}
.amount-wrap {
  background: #f4f5f5;
  padding: 15px;
  padding-right: 20px;
}
.amount-wrap-area[data-v-4f39625a] {
  box-shadow: 3px 10px 16px -4px #d0d0d0;
  background: #fff;
}
</style>
<style>
.ck-editor__editable_inline {
  min-height: 300px !important;
}
</style>